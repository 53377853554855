<template>
  <transition name="scale">
    <div v-show="visible" class="jr-toast">
      <div class="jr-toast_content">
        {{ content }}
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from 'vue'

export default defineComponent({
  setup() {
    const state = reactive({
      content: '',
      type: '',
      delay: ''
    })
  
    const visible = ref(false)
    let timer
    
    const close = () => {
      clearTimeout(timer)
      visible.value = false
      timer = null
    }
    
    const open = () => {
      if (timer) clearTimeout(timer)
      
      visible.value = true
      timer = setTimeout(close, state.delay)

      return close
    }
    
    return {
      ...toRefs(state),
      visible,
      open,
      close
    }
  }
})
</script>

<style lang="less" scoped>
.jr-toast {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  z-index: 2001;
}

.jr-toast_content {
  padding: 13px 43px;
  color: #fff;
  word-break: break-all;
  background-color: rgba(0, 0, 0, .65);
  border-radius: 16px;
}

.scale-enter-active, .scale-leave-active {
  transition: transform .2s;
}

.scale-enter, .scale-leave-to {
  transform: scale(0)
}
</style>