<template>
  <img class="banner" src="@/assets/banner.png" alt="">
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<style lang="less" scoped>
.banner {
  display: block;
  width: 100%;
  height: 37.3333vw;
}
.container {
  padding: 0 3.2vw;
  margin-top: 6.4vw;
}
</style>


